import { Dialog } from "primereact/dialog"

const ExerciseVideoDialog = ({ exerciseVideoDialogVisible, setExerciseVideoDialogVisible, selectedExercise}: any) => {

    const headerContent = (
        <p className="m-0 text-md" style={{ color: "#727A99" }}>{selectedExercise.name}</p>
    );

    return (
        <Dialog
            header={headerContent}
            visible={exerciseVideoDialogVisible}
            style={{ width: '50vw', borderRadius: '24px', overflow: "hidden" }}
            onHide={() => setExerciseVideoDialogVisible(false)}
        >
            <video width={"100%"} controls>
                <source src={selectedExercise.videoUrl} type="video/mp4" />
            </video>
        </Dialog>
    )
}

export default ExerciseVideoDialog;