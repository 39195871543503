const BarList = ({ lang, selectedExercise, planMetricIndex,selectedPatient }: any) => {

    function getWeekday(dateObject: any) {
        const date = new Date(dateObject)
        return new Intl.DateTimeFormat(lang, { weekday: "short" }).format(date);
    }

    function getMonthAndDay(dateObject: any) {
        const date = new Date(dateObject)
        const formattedDate = new Intl.DateTimeFormat(lang, { month: "short", day: "2-digit" }).format(date);

        return formattedDate.replace(" ", "\u00A0");
    }

    function getYear(dateObject: any) {
        const date = new Date(dateObject)
        return new Intl.DateTimeFormat(lang, { year: "numeric" }).format(date);
    }

    function getColorOfBar(percent: any) {
        return percent >= 70
            ? "linear-gradient(to bottom, rgba(35, 207, 149, 0.7), rgba(3, 158, 107, 0.7))"
            : percent >= 30
                ? "linear-gradient(to bottom,rgba(255, 117, 39, 0.7), rgba(208, 75, 0, 0.7))"
                : "linear-gradient(to bottom,rgba(227, 80, 107, 0.3), rgba(197, 32, 62, 0.5))"
    }

    const barList = () => {
        if (selectedExercise) {
            return selectedExercise.exerciseTimeLine.map((timeBar: any) => {
                const successPercentage = Math.round(timeBar.successPercentage)
                const completionPercentage = Math.round(timeBar.completionPercentage)
                return (
                    <div className="flex flex-column justify-content-center align-items-center">
                        <p className="m-0 font-bold text-lg" style={{ color: "#2D3967" }}>
                            {planMetricIndex === 0 ? completionPercentage : successPercentage}</p>
                        <p className="m-0 text-sm" style={{ color: "#2D3967" }}>%</p>
                        <div style={{
                            width: "2rem",
                            height: "28rem",
                            backgroundRepeat: "no-repeat",
                            background: "#E0E3EE url('/barLine.png')",
                            position: "relative",
                            borderRadius: "35px",
                            overflow: "hidden",
                        }}>
                            <div
                                style={{
                                    width: "100%",
                                    height: planMetricIndex === 0
                                        ? completionPercentage + "%"
                                        : successPercentage + "%",
                                    background: getColorOfBar(
                                        planMetricIndex === 0 ? completionPercentage : successPercentage
                                    ),
                                    position: "absolute",
                                    bottom: 0,
                                    borderRadius: "35px 35px 0 0",
                                    transition: "height 0.5s ease-in-out",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    overflow: "hidden"
                                }}
                            >
                            </div>
                        </div>
                        <p className="m-0 font-bold" style={{ color: "#9EA4B9" }}>{getWeekday(timeBar.exerciseTimeLineDate)}</p>
                        <p className="m-0 text-sm" style={{ color: "#9EA4B9" }}>{getMonthAndDay(timeBar.exerciseTimeLineDate)}</p>
                        <p className="m-0 text-sm" style={{ color: "#9EA4B9" }}>{getYear(timeBar.exerciseTimeLineDate)}</p>
                    </div>
                )
            })
        }
        else {
            return <></>
        }
    }

    return (
        <div className="flex justify-content-start align-items-center gap-6 px-4">
            <div className="flex flex-column justify-content-center align-items-center gap-5">
                <div className="flex flex-column  align-items-center">
                    <p className="m-0 text-xl font-bold" style={{ color: "#2D3967" }}>{selectedPatient.firstName}</p>
                    <p className="m-0 text-xl font-bold" style={{ color: "#2D3967" }}>{selectedPatient.lastName}</p>
                </div>

                <img src="/manFigure.png" style={{ width: "10rem" }} />
            </div>

            <div className="flex  gap-8 px-2" style={{ overflowX: "auto", width: "70rem" }}>
                {barList()}
            </div>
        </div>
    )
}

export default BarList