export const AppointmentStatus = {
    Cancelled: {
      value: "Cancelled",
      optionalValues: "UserCancelled,SystemCancelled,HospitalCancelled,ConsultantCancelled",
      textColor: "#F44336",
      backgroundColor: "#FFC2C2",
    },
    Expire: {
      value: "Expire",
      textColor: "#F44336",
      backgroundColor: "#FFC2C2",
    },
    Completed: {
      value: "Completed",
      textColor: "#BEC2D0",
      backgroundColor: "#F5F5F5",
    },
    Approved: {
      value: "Approved",
      textColor: "#23CF95",
      backgroundColor: "#ECFAF5",
    },
    Pending: {
      value: "Pending",
      textColor: "#64ADF0",
      backgroundColor: "#ECFAFF",
    },
  };