import { useEffect, useState } from "react";
import TimeLineBody from "./Components/timeLineBody";
import TimeLineHeader from "./Components/timeLineHeader";

const today = new Date();

const TimeLine = ({t, appointments}:any) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [days, setDays] = useState<any[]>([]);
  const [times, setTimes] = useState<any[]>([]);
  const [minLengthOfDays, setMinLengthOfDays] = useState(15);
  
  const [selectedDay, setSelectedDay] = useState<any>(0);
  const [filteredAppointments, setFilteredAppointments] = useState<any[]>([]);

  const isPreviousDisabled = 
        currentDate.getFullYear() === today.getFullYear() && 
        currentDate.getMonth() === today.getMonth();
      
  const isNow = (hour:any) => {
    const now = new Date();
    return now.getHours() === hour;
  }

  const getDays = (date : Date) => {
    const currentDay = date.getDate();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const remainingDays = [];
    
    let id = 0;
    for (let day = currentDay; day <= daysInMonth; day++) {
      remainingDays.push({id: id, date: new Date(currentYear, currentMonth, day), isNextMonth: false});
      id++;
    }

    const nextMonth = currentMonth + 1;
    for (let day = 1; remainingDays.length < minLengthOfDays; day++) {
      remainingDays.push({id: id, date: new Date(currentYear, nextMonth, day), isNextMonth: true});
      id++;
    }

    return remainingDays;
  }

  const getHourlyTimeSlots = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
        times.push({ id: i, time: `${i.toString().padStart(2, '0')}:00`, isNow: isNow(i) });
    }
    return times;
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate : Date) => {
      const nextMonth = new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
      return nextMonth;
    });
    setSelectedDay(0);
  };

  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => {
        const today = new Date();
        const previousMonth = new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
        
        if (previousMonth.getMonth() === today.getMonth() && previousMonth.getFullYear() === today.getFullYear()) {
          return today;
        }

        return previousMonth;
    });
    setSelectedDay(0);
  };

  const handleToday = () => {
    const timeLineWrapper = document.getElementById('timeline-days-wrapper');
    if(currentDate.getFullYear() !== today.getFullYear() || currentDate.getMonth() !== today.getMonth()) {
      setCurrentDate(today);
    }
    setSelectedDay(0);
    setTimeout(() => {
      if (timeLineWrapper) {
          timeLineWrapper.scrollTo({ left: 0, behavior: 'smooth' });
      }
    }, 100);
  }

  const getSelectedDay = () => {
    if(!days || selectedDay < 0 || selectedDay >= days.length) return '';
    const seleceted = days[selectedDay].date;
    const day = seleceted.getDate();
    const month = seleceted.toLocaleString('en-EN', { month: 'long' });
    const year = seleceted.getFullYear();

    return `${t(month)} ${day}, ${year}`;
  }

  useEffect(() => {
    setTimes(getHourlyTimeSlots());
  }, [])

  useEffect(() => {
    const updatedDays = getDays(currentDate);
    setDays(updatedDays);
  }, [currentDate, minLengthOfDays]);

  useEffect(() => {
    if(!appointments) return;
    if(selectedDay < 0 || selectedDay >= days.length) return;
    const selectedDate = days[selectedDay]?.date;
    const allFilteredAppointments = appointments.filter((a: any) => {
      const date = new Date(a.dateAppointmentStart);
      return (
        date.getFullYear() === selectedDate.getFullYear() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getDate() === selectedDate.getDate()
      );
    });
    if (JSON.stringify(allFilteredAppointments) !== JSON.stringify(filteredAppointments)) {
      setFilteredAppointments(allFilteredAppointments);
    }
  }, [appointments, selectedDay])

  return (
    <>
      <div className="flex flex-column ">
        <TimeLineHeader t={t} getSelectedDay={getSelectedDay} isPreviousDisabled={isPreviousDisabled} handlePreviousMonth={handlePreviousMonth} handleNextMonth={handleNextMonth} handleToday={handleToday} />
        <TimeLineBody t={t} filteredAppointments={filteredAppointments} times={times} days={days} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
      </div>
    </>
  );
};

export default TimeLine;
