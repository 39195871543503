export const countriesTurkish = [
    { name: 'afganistan', code: 'AF', phoneCode: '+93' },
    { name: 'almanya', code: 'DE', phoneCode: '+49' },
    { name: 'amerika Birleşik Devletleri', code: 'US', phoneCode: '+1' },
    { name: 'andorra', code: 'AD', phoneCode: '+376' },
    { name: 'angola', code: 'AO', phoneCode: '+244' },
    { name: 'antiguaVe Barbuda', code: 'AG', phoneCode: '+1-268' },
    { name: 'arjantin', code: 'AR', phoneCode: '+54' },
    { name: 'arnavutluk', code: 'AL', phoneCode: '+355' },
    { name: 'avustralya', code: 'AU', phoneCode: '+61' },
    { name: 'avusturya', code: 'AT', phoneCode: '+43' },
    { name: 'azerbaycan', code: 'AZ', phoneCode: '+994' },
    { name: 'bahamalar', code: 'BS', phoneCode: '+1-242' },
    { name: 'bahreyn', code: 'BH', phoneCode: '+973' },
    { name: 'bangladeş', code: 'BD', phoneCode: '+880' },
    { name: 'barbados', code: 'BB', phoneCode: '+1-246' },
    { name: 'belçika', code: 'BE', phoneCode: '+32' },
    { name: 'belize', code: 'BZ', phoneCode: '+501' },
    { name: 'belarus', code: 'BY', phoneCode: '+375' },
    { name: 'bhutan', code: 'BT', phoneCode: '+975' },
    { name: 'birleşik Arap Emirlikleri', code: 'AE', phoneCode: '+971' },
    { name: 'bolivya', code: 'BO', phoneCode: '+591' },
    { name: 'bosna Hersek', code: 'BA', phoneCode: '+387' },
    { name: 'botsvana', code: 'BW', phoneCode: '+267' },
    { name: 'brezilya', code: 'BR', phoneCode: '+55' },
    { name: 'brunei', code: 'BN', phoneCode: '+673' },
    { name: 'bulgaristan', code: 'BG', phoneCode: '+359' },
    { name: 'burkina Faso', code: 'BF', phoneCode: '+226' },
    { name: 'burundi', code: 'BI', phoneCode: '+257' },
    { name: 'caboVerde', code: 'CV', phoneCode: '+238' },
    { name: 'cezayir', code: 'DZ', phoneCode: '+213' },
    { name: 'cibuti', code: 'DJ', phoneCode: '+253' },
    { name: 'çad', code: 'TD', phoneCode: '+235' },
    { name: 'çekya', code: 'CZ', phoneCode: '+420' },
    { name: 'çin', code: 'CN', phoneCode: '+86' },
    { name: 'danimarka', code: 'DK', phoneCode: '+45' },
    { name: 'dominika', code: 'DM', phoneCode: '+1-767' },
    { name: 'dominikan Cumhuriyeti', code: 'DO', phoneCode: '+1-809' },
    { name: 'ekvator', code: 'EC', phoneCode: '+593' },
    { name: 'ekvator Ginesi', code: 'GQ', phoneCode: '+240' },
    { name: 'el Salvador', code: 'SV', phoneCode: '+503' },
    { name: 'endonezya', code: 'ID', phoneCode: '+62' },
    { name: 'ermenistan', code: 'AM', phoneCode: '+374' },
    { name: 'estonya', code: 'EE', phoneCode: '+372' },
    { name: 'etiyopya', code: 'ET', phoneCode: '+251' },
    { name: 'fas', code: 'MA', phoneCode: '+212' },
    { name: 'fiji', code: 'FJ', phoneCode: '+679' },
    { name: 'filipinler', code: 'PH', phoneCode: '+63' },
    { name: 'filistin', code: 'PS', phoneCode: '+970' },
    { name: 'finlandiya', code: 'FI', phoneCode: '+358' },
    { name: 'fransa', code: 'FR', phoneCode: '+33' },
    { name: 'gabon', code: 'GA', phoneCode: '+241' },
    { name: 'gambiya', code: 'GM', phoneCode: '+220' },
    { name: 'gana', code: 'GH', phoneCode: '+233' },
    { name: 'gine', code: 'GN', phoneCode: '+224' },
    { name: 'gine Bissau', code: 'GW', phoneCode: '+245' },
    { name: 'grenada', code: 'GD', phoneCode: '+1-473' },
    { name: 'guatemala', code: 'GT', phoneCode: '+502' },
    { name: 'güney Afrika', code: 'ZA', phoneCode: '+27' },
    { name: 'güney Kore', code: 'KR', phoneCode: '+82' },
    { name: 'güney Sudan', code: 'SS', phoneCode: '+211' },
    { name: 'gürcistan', code: 'GE', phoneCode: '+995' },
    { name: 'hırvatistan', code: 'HR', phoneCode: '+385' },
    { name: 'hindistan', code: 'IN', phoneCode: '+91' },
    { name: 'hollanda', code: 'NL', phoneCode: '+31' },
    { name: 'honduras', code: 'HN', phoneCode: '+504' },
    { name: 'ırak', code: 'IQ', phoneCode: '+964' },
    { name: 'iran', code: 'IR', phoneCode: '+98' },
    { name: 'irlanda', code: 'IE', phoneCode: '+353' },
    { name: 'ispanya', code: 'ES', phoneCode: '+34' },
    { name: 'israil', code: 'IL', phoneCode: '+972' },
    { name: 'isveç', code: 'SE', phoneCode: '+46' },
    { name: 'isviçre', code: 'CH', phoneCode: '+41' },
    { name: 'italya', code: 'IT', phoneCode: '+39' },
    { name: 'izlanda', code: 'IS', phoneCode: '+354' },
    { name: 'jamaika', code: 'JM', phoneCode: '+1-876' },
    { name: 'japonya', code: 'JP', phoneCode: '+81' },
    { name: 'kamerun', code: 'CM', phoneCode: '+237' },
    { name: 'kanada', code: 'CA', phoneCode: '+1' },
    { name: 'karadağ', code: 'ME', phoneCode: '+382' },
    { name: 'katar', code: 'QA', phoneCode: '+974' },
    { name: 'kazakistan', code: 'KZ', phoneCode: '+7' },
    { name: 'kenya', code: 'KE', phoneCode: '+254' },
    { name: 'kırgızistan', code: 'KG', phoneCode: '+996' },
    { name: 'kolombiya', code: 'CO', phoneCode: '+57' },
    { name: 'komorlar', code: 'KM', phoneCode: '+269' },
    { name: 'kongo', code: 'CG', phoneCode: '+242' },
    { name: 'kosta Rika', code: 'CR', phoneCode: '+506' },
    { name: 'küba', code: 'CU', phoneCode: '+53' },
    { name: 'kuveyt', code: 'KW', phoneCode: '+965' },
    { name: 'laos', code: 'LA', phoneCode: '+856' },
    { name: 'letonya', code: 'LV', phoneCode: '+371' },
    { name: 'liberya', code: 'LR', phoneCode: '+231' },
    { name: 'libya', code: 'LY', phoneCode: '+218' },
    { name: 'lihtenştayn', code: 'LI', phoneCode: '+423' },
    { name: 'litvanya', code: 'LT', phoneCode: '+370' },
    { name: 'lübnan', code: 'LB', phoneCode: '+961' },
    { name: 'lüksemburg', code: 'LU', phoneCode: '+352' },
    { name: 'macaristan', code: 'HU', phoneCode: '+36' },
    { name: 'meksika', code: 'MX', phoneCode: '+52' },
    { name: 'mısır', code: 'EG', phoneCode: '+20' },
    { name: 'moğolistan', code: 'MN', phoneCode: '+976' },
    { name: 'moldova', code: 'MD', phoneCode: '+373' },
    { name: 'monako', code: 'MC', phoneCode: '+377' },
    { name: 'mozambik', code: 'MZ', phoneCode: '+258' },
    { name: 'namibya', code: 'NA', phoneCode: '+264' },
    { name: 'nepal', code: 'NP', phoneCode: '+977' },
    { name: 'nijer', code: 'NE', phoneCode: '+227' },
    { name: 'nijerya', code: 'NG', phoneCode: '+234' },
    { name: 'norveç', code: 'NO', phoneCode: '+47' },
    { name: 'orta Afrika Cumhuriyeti', code: 'CF', phoneCode: '+236' },
    { name: 'özbekistan', code: 'UZ', phoneCode: '+998' },
    { name: 'pakistan', code: 'PK', phoneCode: '+92' },
    { name: 'panama', code: 'PA', phoneCode: '+507' },
    { name: 'paraguay', code: 'PY', phoneCode: '+595' },
    { name: 'peru', code: 'PE', phoneCode: '+51' },
    { name: 'polonya', code: 'PL', phoneCode: '+48' },
    { name: 'portekiz', code: 'PT', phoneCode: '+351' },
    { name: 'romanya', code: 'RO', phoneCode: '+40' },
    { name: 'rusya Federasyonu', code: 'RU', phoneCode: '+7' },
    { name: 'rwanda', code: 'RW', phoneCode: '+250' },
    { name: 'senegal', code: 'SN', phoneCode: '+221' },
    { name: 'sırbistan', code: 'RS', phoneCode: '+381' },
    { name: 'sierraLeone', code: 'SL', phoneCode: '+232' },
    { name: 'singapur', code: 'SG', phoneCode: '+65' },
    { name: 'slovakya', code: 'SK', phoneCode: '+421' },
    { name: 'slovenya', code: 'SI', phoneCode: '+386' },
    { name: 'somali', code: 'SO', phoneCode: '+252' },
    { name: 'sri Lanka', code: 'LK', phoneCode: '+94' },
    { name: 'suudi Arabistan', code: 'SA', phoneCode: '+966' },
    { name: 'şili', code: 'CL', phoneCode: '+56' },
    { name: 'tayland', code: 'TH', phoneCode: '+66' },
    { name: 'tunus', code: 'TN', phoneCode: '+216' },
    { name: 'türkiye', code: 'TR', phoneCode: '+90' },
    { name: 'uganda', code: 'UG', phoneCode: '+256' },
    { name: 'ukrayna', code: 'UA', phoneCode: '+380' },
    { name: 'uruguay', code: 'UY', phoneCode: '+598' },
    { name: 'venezuela', code: 'VE', phoneCode: '+58' },
    { name: 'vietnam', code: 'VN', phoneCode: '+84' },
    { name: 'yemen', code: 'YE', phoneCode: '+967' },
    { name: 'yunanistan', code: 'GR', phoneCode: '+30' },
    { name: 'zambiya', code: 'ZM', phoneCode: '+260' },
    { name: 'zimbabve', code: 'ZW', phoneCode: '+263' }
];