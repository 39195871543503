import React, { useEffect, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useUpdateAppointmentStatus } from "../../hooks/useGetDataQuery";
import { useToastContext } from "../../Lib/Contexts/toastContext";
import CancelPatientAppointmentDialog from "../cancelAppointmentDialog/cancelPatientAppointmentDialog";


const AppointmentTable = ({ upcomingAppointments, setAppointmentList, setUpcomingAppointments, setAppointmentListWithAllData,
    lazyParamsForAppointments, t }: any) => {

    const { showToast }: any = useToastContext();

    const columns = [
        {
            field: "firstName",
        },
        {
            field: "lastName",

        },
        {
            field: "nationalIdentityNumber",

        },
        {
            field: "age",

        },
        {
            field: "status",

        },
        {
            field: "dateAppointmentStart",

        },
        {
            field: "action"
        }
    ];

    const {
        mutate: updateAppointmentMutation,
        isSuccess: isSuccessUpdateAppointmentMutation,
        isError: isErrorUpdateAppointmentMutation
    } = useUpdateAppointmentStatus();

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nationalIdentityNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        age: { value: null, matchMode: FilterMatchMode.EQUALS },
        dateAppointmentStart: { value: null, matchMode: FilterMatchMode.DATE_IS },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    useEffect(() => {
        if (isSuccessUpdateAppointmentMutation) {
            showToast({
                severity: "success",
                summary: "success",
                detail: "Status changed successfully",
            });
        }
    }, [isSuccessUpdateAppointmentMutation]);

    useEffect(() => {
        if (isErrorUpdateAppointmentMutation) {
            showToast({
                severity: "error",
                summary: "error",
                detail: "Error",
            });
        }
    }, [isErrorUpdateAppointmentMutation]);

    const [cancelAppointmentDialog, setCancelAppointmentDialog] = useState(false);
    const [appointmentIdToCancel, setAppointmentIdToCancel] = useState<any>()


    const statusEffectDetector = (status: any) => {
        if (status === "Approved") {
            return {
                backgroundColor: "#ECFAF5",
                color: "#23CF95",
                icon: "/approvedIcon.svg",
                name: t("approved")
            }
        }
        if (status === "Pending") {
            return {
                backgroundColor: "#ECFAFF",
                color: "#64ADF0",
                icon: "/pendingIcon.svg",
                name: t("pending")
            }
        }

        if (status === "Completed") {
            return {
                backgroundColor: "#F5F5F5",
                color: "#727A99",
                icon: "/completedIcon.svg",
                name: t("completed")
            }
        }
        else {
            return {
                backgroundColor: "#FCEEF0",
                color: "#E3506B",
                icon: "/canceledIcon.svg",
                name: t("canceled")
            }
        }
    }


    const statusBodyTemplate = (rowData: any) => {
        return (
            <div className='flex flex-column'>
                <div className='flex justify-content-center align-items-center gap-2' style={{
                    width: "9rem", height: "2.5rem", borderRadius: "10px",
                    backgroundColor: statusEffectDetector(rowData.status).backgroundColor,
                    color: statusEffectDetector(rowData.status).color,
                }}>
                    <img src={statusEffectDetector(rowData.status).icon} />

                    <p>{statusEffectDetector(rowData.status).name}</p>
                </div>
            </div>
        )
    };

    // const statusFilterTemplate = (options: any) => {
    //     return (
    //         <Dropdown
    //             value={options.value}
    //             options={statusList}
    //             onChange={(e) => options.filterApplyCallback(e.value)}
    //             className="p-column-filter"
    //             itemTemplate={statusBodyTemplate}
    //         />
    //     );
    // };

    const dateBodyTemplate = (rowData: any) => {
        if (rowData.dateAppointmentStart) {
            const formattedDate = rowData.dateAppointmentStart.toLocaleDateString("tr-TR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
            });
            return formattedDate.replace(", ", " ")
        };
    }

    // const dateFilterTemplate = (options: any) => {
    //     return <Calendar value={options.value} onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
    // };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <p className='text-xl' style={{ color: "#727A99" }}>{t("upcomingAppointments")}</p>
                {setAppointmentList && <IconField iconPosition="left" className='w-20rem'>
                    <InputIcon className="pi pi-search" />
                    <InputText className='w-full' style={{ borderRadius: "10px" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t("datatableSearchPlaceholder")} />
                </IconField>}

            </div>
        );
    };

    const approveAppointment = (rowData: any) => {
        setAppointmentList((prevAppointments: any) =>
            prevAppointments.map((appointment: any) =>
                appointment.id === rowData.id ? { ...appointment, status: "Approved" } : appointment
            ));

        setAppointmentListWithAllData((prevAppointments: any) =>
            prevAppointments.map((appointment: any) =>
                appointment.id === rowData.id ? { ...appointment, status: "Approved" } : appointment
            ));

        setUpcomingAppointments((prevAppointments: any) =>
            prevAppointments.map((appointment: any) =>
                appointment.id === rowData.id ? { ...appointment, status: "Approved" } : appointment
            ));


        updateAppointmentMutation({
            id: rowData.id,
            status: "Approved"
        })
    }

    const declineAppointment = (rowData: any) => {
        setAppointmentList((prevAppointment: any) => prevAppointment.filter((a: any) => a.id !== rowData.id));

        setAppointmentListWithAllData((prevAppointment: any) => prevAppointment.filter((a: any) => a.id !== rowData.id));

        setUpcomingAppointments((prevAppointment: any) => prevAppointment.filter((a: any) => a.id !== rowData.id));

        updateAppointmentMutation({
            id: rowData.id,
            status: "Rejected",
            dateCancelled: new Date()
        })
    }

    const actionButtons = (rowData: any) => {
        if (rowData.status === "Approved") {
            return (
                <Button label={t("cancel")}
                    style={{ backgroundColor: "#2D3967", border: "none", borderRadius: "10px", width: "6rem", height: "2rem" }} onClick={() => {
                        setAppointmentIdToCancel(rowData.id)
                        setCancelAppointmentDialog(true)
                    }} />
            )
        }
        else {
            return (
                <div className=" flex flex-row gap-2">
                    <Button label={t("approve")} size="small" severity="success"
                        style={{ backgroundColor: "#23CF95", border: "none", borderRadius: "10px", width: "6rem", height: "2rem" }} onClick={() => approveAppointment(rowData)} />

                    <Button label={t("decline")} size="small" severity="danger"
                        style={{ backgroundColor: "#E3506B", border: "none", borderRadius: "10px", width: "6rem", height: "2rem" }} onClick={() => declineAppointment(rowData)} />

                </div>

            )
        }

    }

    const dynamicColumns = columns.map((col: any) => {
        if (col.field === "firstName") {
            return (
                <Column
                    key="firstName"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    sortable
                />
            );
        } else if (col.field === "lastName") {
            return (
                <Column
                    key="lastName"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    sortable
                />
            );
        } else if (col.field === "age") {
            return (
                <Column
                    key="age"
                    field={col.field}
                    header={t(col.field)}
                    dataType='numeric'
                    sortable

                />
            );
        } else if (col.field === "nationalIdentityNumber") {
            return (
                <Column
                    key="nationalIdentityNumber"
                    field={col.field}
                    header={t(col.field)}
                    dataType='numeric'
                    sortable
                />
            );
        }
        else if (col.field === "dateAppointmentStart") {
            return (
                <Column
                    key="dateAppointmentStart"
                    field={col.field}
                    header={t(col.field)}
                    dataType='date'
                    sortable
                    body={dateBodyTemplate}
                    showApplyButton={false}
                />
            );
        } else if (col.field === "status") {
            return (
                <Column
                    key="status"
                    field={col.field}
                    header={t(col.field)}
                    dataType='text'
                    sortable
                    body={statusBodyTemplate}
                    showFilterMatchModes={false}
                    showApplyButton={false}
                />
            );
        } else if (setAppointmentList) {
            return (
                <Column
                    key="action"
                    field={col.field}
                    header={t(col.field)}
                    body={actionButtons}
                />
            );
        }
    });

    return (
        <div className="m-0">
            <DataTable paginator rows={lazyParamsForAppointments.rows}
                value={upcomingAppointments} filters={filters} tableStyle={{ minWidth: '50rem' }} header={header} globalFilterFields={['firstName', 'lastName']} >
                {dynamicColumns}
            </DataTable>

            <CancelPatientAppointmentDialog cancelAppointmentDialog={cancelAppointmentDialog}
                setCancelAppointmentDialog={setCancelAppointmentDialog}
                appointmentIdToCancel={appointmentIdToCancel}
                setAppointmentList={setAppointmentList}
                setAppointmentListWithAllData={setAppointmentListWithAllData}
                setUpcomingAppointments={setUpcomingAppointments}
                t={t} />
        </div>
    )
}

export default AppointmentTable