import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";


const TimeLineHeader = ({ t, getSelectedDay, isPreviousDisabled, handlePreviousMonth, handleNextMonth, handleToday } : any) => {
    return (
        <div className="flex flex-wrap justify-content-between align-items-center p-2">
            <div className="flex gap-2 mb-2">
                <ButtonGroup >
                    <Button  icon="pi pi-angle-left"
                        style={{borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", border: "1.5px solid #2D3967", color: "#2D3967", backgroundColor: "white" }} disabled={isPreviousDisabled} onClick={handlePreviousMonth} />
                    <Button  icon="pi pi-angle-right"
                        style={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px", border: "1.5px solid #2D3967", color: "white", backgroundColor: "#2D3967" }} onClick={handleNextMonth} />
                </ButtonGroup>
                <Button label={t("today")} style={{ width: "6.5rem", borderRadius: "10px", border: "1.5px solid #2D3967", color: "white", backgroundColor: "#2D3967" }} onClick={handleToday} />
            </div>
            <div className="text-3xl font-bold text-center mb-2" style={{ color: "#2D3967" }}>{getSelectedDay()}</div>
            {/* <div className="flex gap-2 mb-2">
                <Button label={t("month")} style={{ width: "6.5rem", borderRadius: "10px", border: "1.5px solid #2D3967", color: "#2D3967", backgroundColor: "white" }} />
                <Button label={t("week")} style={{ width: "6.5rem", borderRadius: "10px", border: "1.5px solid #2D3967", color: "#2D3967", backgroundColor: "white" }} />
                <Button label={t("day")} style={{ width: "6.5rem", borderRadius: "10px", border: "1.5px solid #2D3967", color: "white", backgroundColor: "#2D3967" }} />
            </div> */}
        </div>
    )
}

export default TimeLineHeader;