import { Dialog } from "primereact/dialog";

const TahsilSonucDialog = ({tahsilSonucDialogVisible, setTahsilSonucDialogVisible, data}:any) => {
    return (
        <Dialog style={{width: '60rem', height: "40vh"}} visible={tahsilSonucDialogVisible} onHide={() => setTahsilSonucDialogVisible(false)} >
            <div className="flex flex-wrap gap-3">
                {data && data.data.map((item:any) => {
                    return (
                        <div className="flex flex-column gap-1">
                            <span>Ihtisas Tescil No: {item.ihtisasTescilNo}</span>
                            <span>Ihtisas Tescil Tarihi: {item.ihtisasTescilTarihi}</span>
                            <span>Diploma Tescil No: {item.diplomaTescilNo}</span>
                            <span>Tckn: {item.tckn}</span>
                            <span>Ad: {item.ad}</span>
                            <span>Soyad: {item.soyad}</span>
                            <span>Dogum Tarihi: {item.dogumTarihi}</span>
                            <span>Tahsil Turu: {item.tahsilTuru}</span>
                            <span>Brans Kodu: {item.bransKodu}</span>
                            <span>Brans Adi: {item.bransAdi}</span>
                            <span>Yandal Kodu: {item.yandalKodu}</span>
                            <span>Yandal Adi: {item.yandalAdi}</span>
                        </div>
                    )
                })}
            </div>
        </Dialog>
    )
}

export default TahsilSonucDialog;