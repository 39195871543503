import React from "react";
import { Button } from "primereact/button";
import { Outlet, useNavigate } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { SplitButton } from "primereact/splitbutton";
import "./globalLayout.css";
import AddPatientDialog from "../Utils/AddPatientDialog/addPatientDialog";
import {
    useGetAppointmentListQuery,
    useGetCaruselList,
    useGetMyPanels,
    useGetPatientListQuery,
    useGetSystemProcedures,
    useTokenCheck,
} from "../hooks/useGetDataQuery";
import { LazyParams } from "../services/lazyParams";
import { BlockUI } from "primereact/blockui";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { locale } from "primereact/api";

const GlobalLayout = () => {
    const {
        mutate: getAppointmentListMutation,
        data: appointments,
        isError: isErrorAppointmentList,
        isSuccess: isSuccessAppointmentList,
    } = useGetAppointmentListQuery();

    const {
        mutate: getPatientListMutation,
        data: patients,
        isError: isErrorPatientList,
        isSuccess: isSuccessPatientList,
    } = useGetPatientListQuery();

    const {
        mutate: getCaruselListMutation,
        data: caruselList,
        isSuccess: isSuccessCaruselList,
    } = useGetCaruselList();

    const {
        mutate: getMyPanelsMutation,
        data: panelList,
        isSuccess: isSuccessMyPanelList,
    } = useGetMyPanels();

    const {
        mutate: getSystemProceduresMutation,
        data: systemProcedures,
        isSuccess: isSuccessSystemProcedures
    } = useGetSystemProcedures();

    const [myPanels, setMyPanel] = useState<any>([]);

    const [systemPanels, setSystemPanels] = useState<any>([]);

    const navigate = useNavigate();

    const [blockUI, setBlockUI] = useState(true);

    const [blueBackGroundHeight, setBlueBackGroundHeight] = useState<any>("h-8rem");

    const [pathname, setPathName] = useState<any>();
    const [doctorName, setDoctorName] = useState<any>("");

    const [addPatientDialogVisible, setAddPatientDialogVisible] = useState(false);

    const [patientList, setPatientList] = useState<any>();

    const [appointmentList, setAppointmentList] = useState<any>();
    const [appointmentListWithAllData, setAppointmentListWithAllData] = useState<any>();

    const [caruselExerciseList, setCaruselExerciseList] = useState<any>();

    const [lazyParamsForPatients, setLazyParamsForPatients] = useState<LazyParams>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "registerDate",
        sortOrder: 1,
        filters: {},
    });

    const [lazyParamsForAppointments, setLazyParamsForAppointments] = useState<LazyParams>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "registerDate",
        sortOrder: 1,
        filters: {},
    });

    const [lang, setLang] = useState<any>()

    const countries = [
        { name: 'EN', code: 'GB', lang: "en" },
        { name: 'TR', code: 'TR', lang: 'tr' },
    ];

    const { t, i18n } = useTranslation();

    const { isSuccess: isSuccessTokenCheck, isError: isErrorTokenCheck } =
        useTokenCheck();

    useEffect(() => {
        if (isSuccessTokenCheck) {
            getPatientListMutation(lazyParamsForPatients);
        }
    }, [isSuccessTokenCheck]);

    useEffect(() => {
        if (isErrorTokenCheck) {
            setBlockUI(false);
            navigate({ pathname: "/" });
        }
    }, [isErrorTokenCheck]);

    useEffect(() => {
        if (isSuccessPatientList) {
            preparePatientsData();
            getAppointmentListMutation(lazyParamsForAppointments);
        }
    }, [isSuccessPatientList]);

    useEffect(() => {
        if (isErrorPatientList) {
            setBlockUI(false);
        }
    }, [isErrorPatientList]);

    useEffect(() => {
        if (isSuccessAppointmentList) {
            prepareAppointmentsData();
            getCaruselListMutation()
            getMyPanelsMutation()
            getSystemProceduresMutation();
            setBlockUI(false);
        }
    }, [isSuccessAppointmentList]);

    useEffect(() => {
        if (isErrorAppointmentList) {
            setBlockUI(false);
        }
    }, [isErrorAppointmentList]);

    useEffect(() => {
        if (isSuccessCaruselList) {
            setCaruselExerciseList(caruselList.data)
        }
    }, [isSuccessCaruselList]);

    useEffect(() => {
        if (isSuccessMyPanelList) {
            setMyPanel(panelList.data)
        }
    }, [isSuccessMyPanelList]);

    useEffect(() => {
        if(isSuccessSystemProcedures) {
            setSystemPanels(systemProcedures.data)
        }
    }, [isSuccessSystemProcedures])


    const capitalizeFirstLetter = (string: string) => {
        if (!string) return '';
        let words = string.toLowerCase().split(' ');
        words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        return words.join(' ');
    };


    useEffect(() => {
        /* eslint-disable no-restricted-globals */
        setPathName(location.pathname.substring(location.pathname.lastIndexOf("/"), location?.pathname.length));

        localStorage.getItem("i18nextLng") === "en" ? setLang({ name: 'EN', code: 'GB', lang: "en" })
            : setLang({ name: 'TR', code: 'TR', lang: 'tr' })

        const firstName = JSON.parse(localStorage.getItem("account") as any)?.firstName;
        const lastName = JSON.parse(localStorage.getItem("account") as any)?.lastName;
        if (lastName) {
            setDoctorName("Dr. " + capitalizeFirstLetter(lastName));
        }
        else if (firstName) {
            setDoctorName("Dr. " + capitalizeFirstLetter(firstName));
        }
    }, []);

    useEffect(() => {
        if (pathname) {
            if (pathname === "/prescription") {
                setBlueBackGroundHeight("h-6rem")
            } else {
                setBlueBackGroundHeight("h-8rem")
            }

            if (pathname === "/mobithera") {
                navigate({ pathname: "/mobithera/home" });
            } else {
                navigate({ pathname: "/mobithera" + pathname });
            }
        }
    }, [pathname]);

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang.lang)
            locale(lang.lang);
        }
    }, [lang]);

    const preparePatientsData = () => {
        const purifiedData = patients?.data.map((patient) => ({
            ...patient,
            dateCreated: new Date(patient.dateCreated),
        }));

        if (purifiedData) {
            setPatientList(purifiedData);
        }
    };

    const prepareAppointmentsData = () => {
        const purifiedData = appointments?.data.map((appointmeent) => ({
            ...appointmeent,
            dateAppointmentStart: new Date(appointmeent.dateAppointmentStart),
            dateAppointmentEnd: new Date(appointmeent.dateAppointmentEnd),
        }));

        const tableAppointmentList = [];
        const tableAllAppointmentList = [];

        if (purifiedData) {
            for (const appointment of purifiedData) {
                const patient = patientList.find(
                    (p: any) => p.id === appointment.patientId
                );

                if (patient) {
                    if (
                        appointment.status === "Approved" ||
                        appointment.status === "Pending" ||
                        appointment.status === "Completed"
                    ) {
                        tableAppointmentList.push({
                            id: appointment.id,
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                            nationalIdentityNumber: patient.nationalIdentityNumber,
                            age: patient.age,
                            dateAppointmentStart: appointment.dateAppointmentStart,
                            dateAppointmentEnd: appointment.dateAppointmentEnd,
                            status: appointment.status,
                            gender: patient.gender
                        });
                    }
                    tableAllAppointmentList.push({
                        patientId: appointment.patientId,
                        id: appointment.id,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                        nationalIdentityNumber: patient.nationalIdentityNumber,
                        age: patient.age,
                        dateAppointmentStart: appointment.dateAppointmentStart,
                        dateAppointmentEnd: appointment.dateAppointmentEnd,
                        status: appointment.status,
                        gender: patient.gender
                    });
                }
            }
        }

        tableAppointmentList.sort(
            (a: any, b: any) => a["dateAppointmentStart"] - b["dateAppointmentStart"]
        );
        setAppointmentList(tableAppointmentList);
        setAppointmentListWithAllData(tableAllAppointmentList);
    };

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const selectedCountryTemplate = () => {
        return (
            <div className="dropdown-icon-container">
                <img src="/globe.svg" alt="globe" className="globe-icon" />
            </div>
        );
    };


    const itemsForMenu: MenuItem[] = [
        {
            label: t("home"),
            icon: <img src={window.location.pathname === "/mobithera/home" ? "/RedHome.svg" : "/home.svg"} alt="Home" style={{ marginRight: "5%" }} />,
            command: () => {
                navigate({ pathname: "/mobithera/home" });
                setBlueBackGroundHeight("h-8rem");
            },
            className: window.location.pathname === "/mobithera/home" ? "p-focus" : "",

        },
        {
            label: t("patients"),
            icon: <img src={window.location.pathname === "/mobithera/patients" ? "/RedPatients.svg" : "/patients.svg"} alt="Home" style={{ marginRight: "5%" }} />,
            command: () => {
                navigate({ pathname: "/mobithera/patients" });
                setBlueBackGroundHeight("h-8rem")
            },
            className: window.location.pathname === "/mobithera/patients" ? "p-focus" : "",
        },
        {
            label: t("appointment"),
            icon: <img src={window.location.pathname === "/mobithera/appointment" ? "/RedAppointment.svg" : "/appointment.svg"} alt="Home" style={{ marginRight: "5%" }} />,

            command: () => {
                navigate({ pathname: "/mobithera/appointment" });
                setBlueBackGroundHeight("h-8rem")
            },
            className: window.location.pathname === "/mobithera/appointment" ? "p-focus" : "",
            style: { color: "green" }
        },
        {
            label: t("library"),
            icon: <img src={window.location.pathname === "/mobithera/library" ? "/RedPrescription.svg" : "/prescription.svg"} alt="Home" style={{ marginRight: "5%" }} />,
            command: () => {
                navigate({ pathname: "/mobithera/library" });
                setBlueBackGroundHeight("h-8rem")
            },
            className: window.location.pathname === "/mobithera/library" ? "p-focus" : "",
        },
        {
            template: () => {
                return (
                    <Button
                        label={t("newPatient")}
                        icon={<img src="/addPatientIcon.svg" />}
                        style={{
                            backgroundColor: "#E3506B",
                            color: "white",
                            border: "#E3506B",
                            marginLeft: "0.1rem",
                            height: "2.9rem",
                            borderRadius: "10px",
                            width: "11rem",
                            fontWeight: "bold"
                        }}
                        onClick={() => setAddPatientDialogVisible(true)}
                    />
                );
            },
        },
    ];

    const signOut = () => {
        localStorage.removeItem("account");
        localStorage.removeItem("token");
        navigate({ pathname: "/" });
    };

    const itemsForSplitButton: MenuItem[] = [
        {
            label: t("signOut"),
            command: () => signOut(),
        },
    ];

    const start = (
        <img alt="logo" src="/logo.png" height="40" className="mr-2"></img>
    );
    const end = (
        <div className="flex align-items-center gap-3">

            <Dropdown value={lang} onChange={(e) => setLang(e.value)} options={countries} optionLabel="name"
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                className='globalDropdown' />

            <Avatar
                onClick={() => {
                    navigate({ pathname: "/mobithera/consultantProfile" });
                    setBlueBackGroundHeight("h-8rem")
                }}
                icon="pi pi-user"
                shape="circle"
                size="large"
            />
            <h4 style={{ color: "#FFFFFF" }}>{doctorName}</h4>

            <SplitButton
                model={itemsForSplitButton}
                severity="secondary"
                text
                buttonClassName="split-button-main"
                size="small"
            />

        </div>
    );

    return (
        <div>
            <BlockUI
                blocked={blockUI}
                fullScreen
                style={{ backgroundColor: "transparent" }}
                template={
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
                }
            >
                <Menubar className="w-full fixed" model={itemsForMenu} start={start} end={end} style={{
                    backgroundColor: "#2D3967", border: "none", borderRadius: "0",
                    paddingTop: "1.5rem", paddingLeft: "1.5rem", paddingRight: "1.5rem",
                    zIndex: 1000

                }} />
                <div className={"w-full absolute " + blueBackGroundHeight} style={{ backgroundColor: "#2D3967", top: "5rem" }}></div>
                <div
                    className="h-full w-full flex flex-column m-0 flex-nowrap relative py-4 step2"
                    style={{ paddingLeft: "4rem", paddingRight: "4rem", top: "7rem", zIndex: 999 }}
                >
                    <Outlet
                        context={{
                            t,
                            appointmentListWithAllData,
                            setAppointmentListWithAllData,
                            appointmentList,
                            setAppointmentList,
                            patientList,
                            setPatientList,
                            lazyParamsForPatients,
                            setLazyParamsForPatients,
                            lazyParamsForAppointments,
                            setLazyParamsForAppointments,
                            systemPanels,
                            myPanels,
                            setMyPanel,
                            caruselExerciseList,
                            setBlueBackGroundHeight
                        }}
                    />
                </div>

                <div className="w-full flex justify-content-center align-items-center"
                    style={{
                        marginTop: "7rem",
                        height: "3rem",
                        backgroundColor: "#FFFFFF",
                        position: "relative",

                    }}
                >
                    <p className="text-sm" style={{ color: "#9EA4B9" }}>© 2024 <span className="font-bold" style={{ color: "#2D3967" }}>Mobithera Technology.</span> All Rights Reserved.</p>
                </div>

                <AddPatientDialog
                    t={t}
                    patientList={patientList}
                    setPatientList={setPatientList}
                    addPatientDialogVisible={addPatientDialogVisible}
                    setAddPatientDialogVisible={setAddPatientDialogVisible}
                />

            </BlockUI>
        </div>
    );
};

export default GlobalLayout;
