import { useEffect, useState } from "react";
import TimeLine from "./timeLine";
import { useOutletContext } from "react-router-dom";
import AppointmentTable from "../Utils/Tables/appointmentTable";
import { Card } from "primereact/card";

const Appointment = () => {
  const { t }: any = useOutletContext();
  const { appointmentList }: any = useOutletContext();
  const { setAppointmentList }: any = useOutletContext();
  const { setAppointmentListWithAllData }: any = useOutletContext();
  const { lazyParamsForAppointments }: any = useOutletContext();

  const [upcomingAppointments, setUpcomingAppointments] = useState<any>()
  const [upcomingAppointmentsForTimeline, setUpcomingAppointmentsForTimeline] = useState<any>()

  useEffect(() => {
    if (appointmentList) {
      const now = new Date();
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      setUpcomingAppointments(appointmentList.filter((a: any) => a.dateAppointmentStart > now && a.status !== "Completed"));
      setUpcomingAppointmentsForTimeline(appointmentList.filter((a: any) => a.dateAppointmentStart > startOfDay));
    }
  }, [appointmentList]);

  return (
    <div>
      <Card style={{ borderRadius: "24px", position: "relative" }}>
        <TimeLine t={t} appointments={upcomingAppointmentsForTimeline} />
      </Card>
      <Card className="mt-4" style={{ borderRadius: "24px", position: "relative" }}>
        <AppointmentTable
          upcomingAppointments={upcomingAppointments}
          setAppointmentList={setAppointmentList}
          setAppointmentListWithAllData={setAppointmentListWithAllData}
          setUpcomingAppointments={setUpcomingAppointments}
          lazyParamsForAppointments={lazyParamsForAppointments}
          t={t}
        />
      </Card>
    </div>
  );
};

export default Appointment;
