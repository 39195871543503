import React from 'react';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';


const PatientCarusel = ({ caruselPatients, setAddPatientDialogVisible, t }: any) => {
    const navigate = useNavigate();
    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";

    const responsiveOptions = [
        {
            breakpoint: '1440px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    function formatDateWithoutTime(date: any) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(lang, options);
    }
    function formatDateTime(date: any) {
        const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
        const formattedTime = date.toLocaleTimeString(lang, timeOptions);

        return `${formattedTime}`;
    }

    const viewPatientDetails = (rowData: any) => {
        localStorage.setItem("patient", JSON.stringify(rowData));
        navigate({ pathname: "/mobithera/prescription" })
    }

    const patientTemplate = (patient: any) => {
        if (patient.add) {
            return (
                <div onClick={() => setAddPatientDialogVisible(true)} className='flex flex-column justify-content-center align-items-center m-2 h-25rem p-4 gap-3 clickableCarouselDiv'
                    style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "18px", boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)"
                    }}>
                    <img src="/addPatientIconRed.svg" />
                    <div className='flex flex-column  align-items-center'>
                        <p className='text-xl m-0 font-bold' style={{ color: "#2D3967" }}>{lang === "en-US" ? t("create") : t("newPatient")}</p>
                        <p className='text-xl m-0 font-bold' style={{ color: "#2D3967" }}>{lang === "en-US" ? t("newPatient") : t("create")}</p>
                    </div>

                </div>
            )
        } else if (patient.empty) {
            return (
                <div className='flex flex-column justify-content-start align-items-start m-2 h-25rem p-4 gap-3'
                    style={{
                        whiteSpace: "nowrap", backgroundColor: "#FFFFFF",
                        borderRadius: "18px", boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)"
                    }}>
                    <img src="/emptyPatient.png"
                        style={{ width: "100%", height: "10rem", borderRadius: "18px", objectFit: "cover" }} />

                    <div className='flex flex-column'>
                        <p className='text-lg m-0 font-bold' style={{ color: "#2D3967", whiteSpace: "wrap" }}>{t("patientName")}</p>
                        <p className='text-lg m-0 font-bold' style={{ color: "#2D3967", whiteSpace: "wrap" }}>{t("patientSurname")}</p>
                    </div>


                    <div className='flex flex-column gap-2 align-items-start'>
                        <div className='flex flex-row  gap-2'>
                            <img src="/date.svg" />
                            <span className=' text-sm' style={{ color: "#727A99" }}>{t("registerDate")}</span>
                        </div>
                        <div className='flex flex-row  gap-2'>
                            <img src="/clock.svg" />
                            <span className=' text-sm' style={{ color: "#727A99" }}>{t("registerTime")}</span>
                        </div>
                    </div>

                    <Button
                        className="w-full"
                        style={{ backgroundColor: "#BEC2D0", border: "none", borderRadius: "10px",height:"2.5rem" }}
                        onClick={() => viewPatientDetails(patient)}
                    >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {t("waiting")}
                            <img src="/loadingIcon.svg" />
                        </span>
                    </Button>
                </div>
            )
        } else {
            return (
                <div className='flex flex-column justify-content-start align-items-start m-2 h-25rem p-4 gap-3'
                    style={{
                        whiteSpace: "nowrap", backgroundColor: "#FFFFFF",
                        borderRadius: "18px", boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)"
                    }}>
                    <img src={patient.gender === "Male" ? "/man.png" : "/Female.png"}
                        style={{ width: "100%", height: "10rem", borderRadius: "18px", objectFit: "cover" }} />

                    <div className='flex flex-column'>
                        <p className='text-lg m-0 font-bold' style={{ color: "#2D3967", whiteSpace: "wrap" }}>{patient.firstName}</p>
                        <p className='text-lg m-0 font-bold' style={{ color: "#2D3967", whiteSpace: "wrap" }}>{patient.lastName}</p>
                    </div>


                    <div className='flex flex-column gap-2 align-items-start'>
                        <div className='flex flex-row  gap-2'>
                            <img src="/date.svg" />
                            <span className=' text-sm' style={{ color: "#727A99" }}>{formatDateWithoutTime(patient.dateCreated)}</span>
                        </div>
                        <div className='flex flex-row  gap-2'>
                            <img src="/clock.svg" />
                            <span className=' text-sm' style={{ color: "#727A99" }}>{formatDateTime(patient.dateCreated)}</span>
                        </div>
                    </div>

                    <Button
                        className="w-full"
                        style={{ backgroundColor: "#E3506B", border: "none", borderRadius: "10px",height:"2.5rem"}}
                        onClick={() => viewPatientDetails(patient)}
                    >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {t("viewDetail")}
                            <img src="/rightArrow.svg" />
                        </span>
                    </Button>
                </div>
            )
        }

    }

    return (
        <Card style={{ borderRadius: "24px", position: "relative", backgroundColor: "#F5F5F5", boxShadow: "none" }}>
            <p className='text-xl mx-2' style={{ color: "#727A99" }}>{t("recentPatients")}</p>
            <Carousel value={caruselPatients} numVisible={5} numScroll={1} itemTemplate={patientTemplate} responsiveOptions={responsiveOptions} />
        </Card>

    )
}

export default PatientCarusel