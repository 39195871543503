import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PlansCard from './PlansCardTab/plansCard';
import { useGetPlanListByPatientQuery } from '../hooks/useGetDataQuery';
import { BlockUI } from 'primereact/blockui';
import "./prescription.css"
import AddExercisesDialog from '../Utils/ExerciseDialog/addExercisesDialog';
import PatientDetailCard from './patientDetailCard';

import ControlPanel from './controlPanel';
import VisitHistory from './visitHistory';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';


const Prescription = () => {
    const { t }: any = useOutletContext();
    const { setBlueBackGroundHeight }: any = useOutletContext();
    const { patientList }: any = useOutletContext();


    // const tempPlans = [
    //     {
    //         planName: "Plan1",
    //         planStartDate: new Date("2025-01-01"),
    //         planEndDate: new Date("2025-01-14"),
    //         exerciseList: [
    //             {
    //                 exerciseId: 1,
    //                 exerciseName: "Exercise1 Exercise1Exercise1",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-01"),
    //                         completionPercentage: 80,
    //                         successPercentage: 60,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 30
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-02"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-03"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-05"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-06"),
    //                         completionPercentage: 10,
    //                         successPercentage: 20,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-07"),
    //                         completionPercentage: 30,
    //                         successPercentage: 50,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-08"),
    //                         completionPercentage: 0,
    //                         successPercentage: 0,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-09"),
    //                         completionPercentage: 50,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 20
    //                     }
    //                 ]
    //             },
    //             {
    //                 exerciseId: 2,
    //                 exerciseName: "Exercise2",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-01"),
    //                         completionPercentage: 80,
    //                         successPercentage: 70,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 30
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-02"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-03"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-05"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-09"),
    //                         completionPercentage: 50,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 20
    //                     }
    //                 ]
    //             },
    //             {
    //                 exerciseId: 3,
    //                 exerciseName: "Exercise3",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-01"),
    //                         completionPercentage: 80,
    //                         successPercentage: 70,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 30
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-02"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-03"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-05"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-09"),
    //                         completionPercentage: 50,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 20
    //                     }
    //                 ]
    //             },
    //             {
    //                 exerciseId: 4,
    //                 exerciseName: "Exercise4",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 65,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 45,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-10"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-14"),
    //                         completionPercentage: 0,
    //                         successPercentage: 0,
    //                         totalDurationInMinutes: 0,
    //                         completedDurationInMinutes: 0
    //                     }
    //                 ]
    //             },
    //             {
    //                 exerciseId: 5,
    //                 exerciseName: "Exercise5",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 65,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 45,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-10"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-14"),
    //                         completionPercentage: 0,
    //                         successPercentage: 0,
    //                         totalDurationInMinutes: 0,
    //                         completedDurationInMinutes: 0
    //                     }
    //                 ]
    //             },
    //             {
    //                 exerciseId: 6,
    //                 exerciseName: "Exercise6",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 25,
    //                         successPercentage: 10,
    //                         totalDurationInMinutes: 45,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-10"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-14"),
    //                         completionPercentage: 10,
    //                         successPercentage: 12,
    //                         totalDurationInMinutes: 0,
    //                         completedDurationInMinutes: 0
    //                     }
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         planName: "Plan2",
    //         planStartDate: new Date("2025-01-01"),
    //         planEndDate: new Date("2025-01-14"),
    //         exerciseList: [
    //             {
    //                 exerciseId: 1,
    //                 exerciseName: "Exercise3",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-01"),
    //                         completionPercentage: 80,
    //                         successPercentage: 70,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 30
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-05"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-09"),
    //                         completionPercentage: 50,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 40,
    //                         completedDurationInMinutes: 20
    //                     }
    //                 ]
    //             },
    //             {
    //                 exerciseId: 2,
    //                 exerciseName: "Exercise4",
    //                 exerciseImg: "noImg",
    //                 exerciseTimeLine: [
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-04"),
    //                         completionPercentage: 65,
    //                         successPercentage: 30,
    //                         totalDurationInMinutes: 45,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-10"),
    //                         completionPercentage: 90,
    //                         successPercentage: 80,
    //                         totalDurationInMinutes: 50,
    //                         completedDurationInMinutes: 40
    //                     },
    //                     {
    //                         exerciseTimeLineDate: new Date("2025-01-14"),
    //                         completionPercentage: 0,
    //                         successPercentage: 0,
    //                         totalDurationInMinutes: 0,
    //                         completedDurationInMinutes: 0
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // ]

    const [selectedPatient, setSelectedPatient] = useState<any>(JSON.parse(localStorage.getItem("patient") || '{}'));
    const { data, isSuccess, isError } = useGetPlanListByPatientQuery(selectedPatient?.id);
    const [codeDialogVisible, setCodeDialogVisible] = useState<boolean>(false);

    const [planList, setPlanList] = useState<any>([])
    const [plan, setPlan] = useState<any>()


    const [blockUI, setBlockUI] = useState(true);

    const [exerciseDialogVisible, setExerciseDialogVisible] = useState<any>();

    const [tabIndex, setTabIndex] = useState<any>(0)

    const [planTimeLine, setPlanTimeLine] = useState<any>(t("ongoing"))

    const [patientInfoSideBar, setPatientInfoSideBar] = useState<any>(false)


    const [painDateOptions, setPainDateOptions] = useState<any>(
        [
            {
                name: "22/7/2023",
                value: new Date(2023, 7, 22),
                anemnez: "",
                notes: ""
            },
            {
                name: "22/8/2023",
                value: new Date(2023, 8, 22),
                anemnez: "",
                notes: ""
            },
            {
                name: "22/9/2023",
                value: new Date(2023, 9, 22),
                anemnez: "",
                notes: ""
            }

        ]
    )
    const [paitentHistoryDate, setPaitentHistoryDate] = useState<any>(painDateOptions[0].value)


    useEffect(() => {
        if (isSuccess) {
            setPlanList(data?.data)
            // setPlanList(tempPlans)
            setBlockUI(false);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        setBlueBackGroundHeight("h-6rem")
    }, []);

    useEffect(() => {
        if (isError) setBlockUI(false);
    }, [isError]);

    useEffect(() => {
        setSelectedPatient(JSON.parse(localStorage.getItem("patient") || '{}'))
    }, [patientList]);

    return (
        <BlockUI
            blocked={blockUI}
            fullScreen
            style={{ backgroundColor: "transparent" }}
            template={
                <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
            }
        >
            {selectedPatient ? (
                <div className="flex flex-column justify-content-center gap-3">
                    <ControlPanel
                        t={t}
                        setExerciseDialogVisible={setExerciseDialogVisible}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        planTimeLine={planTimeLine}
                        setPlanTimeLine={setPlanTimeLine}
                        paitentHistoryDate={paitentHistoryDate}
                        setPaitentHistoryDate={setPaitentHistoryDate}
                        painDateOptions={painDateOptions} />
                    {tabIndex === 0 &&
                        <>
                            <PlansCard
                                t={t}
                                plan={plan}
                                setPlan={setPlan}
                                planList={planList}
                                selectedPatient={selectedPatient} 
                                setCodeDialogVisible={setCodeDialogVisible} />
                        </>
                    }

                    {tabIndex === 1 &&
                        <>
                            <VisitHistory
                                paitentHistoryDate={paitentHistoryDate}
                                painDateOptions={painDateOptions}
                                setPainDateOptions={setPainDateOptions}
                                t={t} />
                        </>
                    }

                    <Button
                        onClick={() => setPatientInfoSideBar(true)}
                        style={{
                            backgroundColor: "#2D3967",
                            color: "white",
                            border: "#2D3967",
                            height: "3rem",
                            borderRadius: "10px",
                            width: "12rem",
                            fontWeight: "bold",
                            bottom: 50,
                            right: 65,
                            position: "fixed"
                        }}
                    >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            <img src="/accept.svg" />
                            {t("patientInfo")}
                        </span>
                    </Button>

                    <Sidebar className='prescription' visible={patientInfoSideBar} position="right" onHide={() => setPatientInfoSideBar(false)}>
                        <PatientDetailCard
                            t={t}
                            selectedPatient={selectedPatient}
                            planList={planList} />
                    </Sidebar>

                    <AddExercisesDialog t={t}
                        selectedPatient={selectedPatient}
                        exerciseDialogVisible={exerciseDialogVisible}
                        setExerciseDialogVisible={setExerciseDialogVisible}
                        setPlan={setPlan}
                        setSelectedPatient={setSelectedPatient} 
                        codeDialogVisible={codeDialogVisible} 
                        setCodeDialogVisible={setCodeDialogVisible} />

                </div>


            ) : (
                <>
                </>
            )}
        </BlockUI>
    )
}

export default Prescription