import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const CarePlansChart = ({ t, carePlanStatisticsChartData }: any) => {

    const [dataFrame, setDataFrame] = useState<any>([0, 0, 0, 0])


    useEffect(() => {
        if (carePlanStatisticsChartData) {
            let count = 0;
            let upcoming = 0
            let completed = 0
            let inprogress = 0


            for (const data of carePlanStatisticsChartData) {
                count = count + data.count

                if (data.status === 'NotStarted') {
                    upcoming = data.count
                }
                else if (data.status === 'Completed') {
                    completed = data.count
                }
                else {
                    inprogress = data.count
                }
            }
            setDataFrame([count,upcoming,inprogress,completed])
        }
    }, [carePlanStatisticsChartData]);

    const data = {
        datasets: [
            {
                data: [dataFrame[1], dataFrame[0] - dataFrame[1]],
                backgroundColor: [
                    '#2D3967',
                    '#EBEDF2'
                ],
                borderWidth: 6,
                borderRadius: 50,
                cutout: '55%',
            },
            {
                data: [dataFrame[2], dataFrame[0] - dataFrame[2]],
                backgroundColor: [
                    '#E3506B',
                    '#EBEDF2'
                ],
                borderWidth: 8,
                borderRadius: 50,
                cutout: '45%',
            },
            {
                data: [dataFrame[3], dataFrame[0] - dataFrame[3]],
                backgroundColor: [
                    '#64ADF0',
                    '#EBEDF2'
                ],
                borderWidth: 10,
                borderRadius: 50,
                cutout: '35%',
            }
        ]
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            mode: null,
        },
        plugins: {
            tooltip: {
                enabled: false,
            }
        },
        layout: {
            padding: {
                top: 0,
                bottom: 5,
                left: 0,
                right: 30
            }
        }
    };

    return (
        <div className='flex justify-content-between align-items-center px-4'>
            <div style={{ width: '15rem', height: '15rem' }} >
                <Doughnut data={data} options={options} />
            </div>

            <div className='flex flex-column justify-content-center align-items-end mb-2'>
                <p className='text-md' style={{ color: "#727A99", textAlign: "right" }}>{t("carePlanStatistics")}</p>
                <div className='flex flex-column justify-content-center'>
                    <div className='flex justify-content-end align-items-center gap-2 p-0'>
                        <span style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: "#2D3967",
                            borderRadius: '50%',
                        }}></span>
                        <p className='m-0 font-bold' style={{ color: "#2D3967" }}>{dataFrame[1]}</p>

                    </div>
                    <p className='m-0 text-xs' style={{ color: "#727A99" }}>{t('upcoming')}</p>
                </div>
                <div className='flex flex-column justify-content-center'>
                    <div className='flex justify-content-end align-items-center gap-2 p-0'>
                        <span style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: "#E3506B",
                            borderRadius: '50%',
                        }}></span>
                        <p className='m-0 font-bold' style={{ color: "#E3506B" }}>{dataFrame[2]}</p>

                    </div>
                    <p className='m-0 text-xs' style={{ color: "#727A99" }}>{t('inprogress')}</p>
                </div>
                <div className='flex flex-column justify-content-center'>
                    <div className='flex justify-content-end align-items-center gap-2 p-0'>
                        <span style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: "#64ADF0",
                            borderRadius: '50%',
                        }}></span>
                        <p className='m-0 font-bold' style={{ color: "#64ADF0" }}>{dataFrame[3]}</p>

                    </div>
                    <p className='m-0 text-xs' style={{ color: "#727A99" }}>{t('completed')}</p>
                </div>


            </div>
        </div>
    );
};

export default CarePlansChart;
