import { useEffect } from 'react';

const Download = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            window.location.href = "https://play.google.com/store/search?q=mobithera&c=apps";
        } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
            window.location.href = "https://apps.apple.com/us/app/mobithera/id6468960239";
        } 
    }, []);

    return (
        <>
        </>
    );
}

export default Download;
