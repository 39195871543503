import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import PatientStatisticsChart from './patientStatisticsChart';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const Bottom2Card = ({ t, upcomingAppointments }: any) => {

    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";
    const navigate = useNavigate();

    const dayAndDate = (date: any) => {
        const dayFormatter = new Intl.DateTimeFormat(lang, { weekday: 'long' });
        const dateFormatter = new Intl.DateTimeFormat(lang, { day: 'numeric' });
        const dayOfWeek = dayFormatter.format(date);
        let dayOfMonth = dateFormatter.format(date);

        return `${dayOfWeek}, ${dayOfMonth}`;
    }

    const monthAndYear = (date: any) => {
        const monthFormatter = new Intl.DateTimeFormat(lang, { month: 'long', year: 'numeric' });
        return monthFormatter.format(date);
    }

    const time = (date: any) => {
        const timeFormatter = new Intl.DateTimeFormat(lang, { hour: 'numeric', minute: 'numeric', hour12: true });
        const timeParts = timeFormatter.formatToParts(date);
        return `${timeParts.find(part => part.type === 'hour')?.value}:${timeParts.find(part => part.type === 'minute')?.value}`;

    }

    const zone = (date: any) => {
        const timeFormatter = new Intl.DateTimeFormat(lang, { hour: 'numeric', minute: 'numeric', hour12: true });
        const timeParts = timeFormatter.formatToParts(date);
        return timeParts.find(part => part.type === 'dayPeriod')?.value.toUpperCase();
    }


    const navigateToAppointments = () => {
        navigate({ pathname: "/mobithera/appointment" })
    }

    const patientStatisticsCardInfo = (upcomingAppointments: any) => {
        let result: any = <></>;
        if (upcomingAppointments) {
            result = upcomingAppointments.map((appointment: any) => {
                return (
                    <div className='flex justify-content-between align-items-center mt-2 gap-4' style={{ whiteSpace: "nowrap" }}>
                        <Avatar icon="pi pi-user" size="large" />

                        <div className='flex flex-column justify-content-center align-items-start'>
                            <p className='m-0 text-lg font-bold' style={{ color: "#2D3967" }}>{appointment.firstName} {appointment.lastName}</p>
                            <p className='m-0 text-sm' style={{ color: "#727A99" }}>---</p>
                        </div>
                        <div className='flex flex-column justify-content-center align-items-center'>
                            <p className='m-0 text-lg font-bold' style={{ color: "#2D3967" }}>{dayAndDate(appointment.dateAppointmentStart)}</p>
                            <p className='m-0 text-sm' style={{ color: "#727A99" }}>{monthAndYear(appointment.dateAppointmentStart)}</p>
                        </div>
                        <div className='flex flex-column justify-content-center align-items-center'>
                            <p className='m-0 text-lg font-bold' style={{ color: "#2D3967" }}>{time(appointment.dateAppointmentStart)}</p>
                            <p className='m-0 text-sm' style={{ color: "#727A99" }}>{zone(appointment.dateAppointmentStart)}</p>
                        </div>
                        <div className='flex flex-column justify-content-center align-items-center'>
                            <div className='flex justify-content-center align-items-center gap-2' style={{
                                width: "9rem", height: "2.5rem", borderRadius: "10px",
                                backgroundColor: appointment.status === "Approved" ? "#ECFAF5" : "#ECFAFF",
                                color: appointment.status === "Approved" ? "#23CF95" : "#64ADF0",
                            }}>
                                <img src={appointment.status === "Approved" ? "/approvedIcon.svg" : "/pendingIcon.svg"} />

                                <p>{appointment.status === "Approved" ? t("approved") : t("pending")}</p>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return result
    }

    return (
        <div className="grid mt-2 homeCards">
            <div className='col'>
                <Card className="h-20rem" style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-center p-4 gap-3">
                        <div className='flex justify-content-between align-items-center gap-4'>
                            <p className=' text-xl m-0' style={{ color: "#727A99", whiteSpace: "nowrap" }}>{t("upcomingAppointments")}</p>
                            <Button label={t("seeMore")} onClick={navigateToAppointments}
                                style={{ width: "9rem", height: "2.5rem", borderRadius: "10px", border: "1.5px solid #2D3967", color: "#2D3967", backgroundColor: "white" }} />
                        </div>

                        {patientStatisticsCardInfo(upcomingAppointments)}
                    </div>


                </Card>
            </div>
            <div className='col'>
                <Card className="h-20rem" style={{ borderRadius: "24px", position: "relative" }}>
                    <PatientStatisticsChart t={t} />
                </Card>
            </div>
        </div>
    )
}

export default Bottom2Card