import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const AppointmentsStatisticsChart = ({ t, appointmentsStatusStats }: any) => {
    const [totalSum, setTotalSum] = useState<any>()

    useEffect(() => {
        if (appointmentsStatusStats) {
            const sum = appointmentsStatusStats[0] + appointmentsStatusStats[1] + appointmentsStatusStats[2]
            setTotalSum(sum)
        }
        else {
            setTotalSum(0)
        }
    }, [appointmentsStatusStats]);

    const data = {
        labels: ['Completed', 'Canceled', 'Missed'],
        datasets: [
            {
                data: [appointmentsStatusStats ? appointmentsStatusStats[0] : 0, appointmentsStatusStats ? appointmentsStatusStats[1] : 0, appointmentsStatusStats ? appointmentsStatusStats[2] : 0],
                backgroundColor: ['#2D3967', '#E3506B', '#64ADF0'],
                borderWidth: 5,
                borderRadius: 50,
            },
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
            mode: null,
        },
        cutout: '85%',
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        layout: {
            padding: {
                top: 20,
                bottom: 20,
                left: 0,
                right: 20
            }
        }
    };

    return (
        <div className='flex justify-content-between align-items-center px-4'>
            <div style={{ width: '15rem', height: '15rem' }} >
                <Doughnut data={data} options={options} />
                <div
                    style={{
                        position: 'absolute',
                        bottom: '5rem',
                        left: '7.5rem',
                        fontSize: '3rem',
                        fontWeight: 'bold',
                        color: "#2D3967"
                    }}
                >
                    {totalSum}
                </div>
            </div>

            <div className='flex flex-column justify-content-center align-items-end mb-2'>

                <p className='text-md ' style={{ color: "#727A99", textAlign: "right" }}>{t("appointment")}</p>
                <br />
                <div className='flex flex-column justify-content-center'>
                    <div className='flex justify-content-end align-items-center gap-2 p-0'>
                        <span style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: "#2D3967",
                            borderRadius: '50%',
                        }}></span>
                        <p className='m-0 font-bold' style={{ color: "#2D3967" }}>{appointmentsStatusStats ? appointmentsStatusStats[0] : ''}</p>

                    </div>
                    <p className='m-0 text-xs' style={{ color: "#727A99" }}>{t('completed')}</p>
                </div>
                <div className='flex flex-column justify-content-center'>
                    <div className='flex justify-content-end align-items-center gap-2 p-0'>
                        <span style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: "#E3506B",
                            borderRadius: '50%',
                        }}></span>
                        <p className='m-0 font-bold' style={{ color: "#E3506B" }}>{appointmentsStatusStats ? appointmentsStatusStats[1] : ''}</p>

                    </div>
                    <p className='m-0 text-xs' style={{ color: "#727A99" }}>{t('canceled')}</p>
                </div>
                <div className='flex flex-column justify-content-center'>
                    <div className='flex justify-content-end align-items-center gap-2 p-0'>
                        <span style={{
                            width: '1rem',
                            height: '1rem',
                            backgroundColor: "#64ADF0",
                            borderRadius: '50%',
                        }}></span>
                        <p className='m-0 font-bold' style={{ color: "#64ADF0" }}>{appointmentsStatusStats ? appointmentsStatusStats[2] : ''}</p>

                    </div>
                    <p className='m-0 text-xs' style={{ color: "#727A99" }}>{t('missed')}</p>
                </div>
            </div>
        </div>
    );
};

export default AppointmentsStatisticsChart;
