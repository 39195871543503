import { useDrop } from 'react-dnd';
import DraggableExercise from './draggableExercise';

const DropArea = ({ cardType, exercises, onDrop, selectedExercises, exerciseIsNotGiven }: any) => {
    const [, drop] = useDrop({
        accept: 'EXERCISE',
        drop: (item) => onDrop(item, cardType),
    });

    const errorBorder = exerciseIsNotGiven ? selectedExercises.length !== 0 ? 
    { border: "none", overflow: "auto",borderRadius: "24px" } : 
    { border: "2px solid #E3506B", overflow: "auto",borderRadius: "24px" } : 
    { border: "none", overflow: "auto",borderRadius: "24px" }

    if (cardType === "selectedExercises" && selectedExercises.length === 0) {
        return (
            <div ref={drop} className="h-30rem  flex justify-content-center align-items-center" style={errorBorder}>
                <div className='w-full h-26rem m-5 flex flex-column justify-content-center  align-items-center' style={{ border: "1px dashed #2D3967", borderRadius: "18px" }}>
                    <img src="/dropIcon.svg" style={{ objectFit: "contain" }} />
                    <p className='text-md font-bold' style={{ color: '#2D3967' }}>Drag & Drop Exercises Here</p>
                </div>
            </div>
        );
    }
    else {
        return (
            <div ref={drop} className="h-30rem p-2" style={{ overflow: "auto" }}>
                {exercises.map((exercise: any) => (
                    <DraggableExercise key={exercise.id} exercise={exercise} cardType={cardType} />
                ))}
            </div>
        );
    }

};

export default DropArea;